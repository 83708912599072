'use client';

import { useRouter } from '@/i18n/routing';
import { useEffect, useMemo, useState } from 'react';
import { HomeButton } from '../_components/HomeButton';
import { HomeHeader } from '../_components/HomeHeader';

const HomeFeature = () => {
  const router = useRouter();

  const messages = useMemo(
    () => [
      'Büyük Bir Maceraya Hazır mısın?',
      'Arkadaşlarını Davet Et ve Eğlenceye Katıl!',
      'Sunucumuza Katıl ve Bloklar Arasında Kaybol!',
      'Şimdi Katıl ve Minecraft Efsanesi Ol!',
      'Bir Hesap Oluştur ve Maceraya Başla!',
      'Keşfetmeye Hazır mısın? Kayıt Ol!',
    ],
    []
  );

  const [randomMessage, setRandomMessage] = useState(
    messages[Math.floor(Math.random() * messages.length)]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      setRandomMessage(messages[randomIndex]);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [messages]);

  return (
    <div className="flex justify-center items-center bg-[url(/minecraft-home-page-min.webp)] -mt-32 bg-cover bg-center h-screen">
      <div className="flex flex-col justify-center items-center gap-14">
        <HomeHeader text={randomMessage} />

        <HomeButton
          label="Hemen Oyna"
          onClick={() => router.push('/game/lobby')}
        />
      </div>
    </div>
  );
};

export default HomeFeature;
