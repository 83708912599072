import routes from './routes';

export const locales = ['en', 'tr'] as const;
export const defaultLocale = 'tr' as const;

export type Locale = (typeof locales)[number];

export interface Pathnames {
  [key: string]: string | Record<Locale, string>;
}

export const pathnames = routes satisfies Pathnames;

export const localePrefix = 'always'; // 'never' kullanıldığında dil parametresi URL'den kaldırılır

// modalların reponsivine bak sadece mobile yapılmış
