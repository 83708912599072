import { cn } from '@/lib/utils';
import { Button, ButtonProps } from 'primereact/button';

export const HomeButton = ({ ...props }: ButtonProps) => {
  return (
    <div className="relative overflow-hidden rounded-full active:scale-95 dark:bg-zinc-900 bg-emerald-500 shadow border dark:border-zinc-800 border-zinc-400 p-0.5">
      <span className="absolute inset-[-1000%] animate-[spin_5s_linear_infinite_reverse] dark:bg-[conic-gradient(from_90deg_at_50%_50%,#fff_0%,#09090B_7%)] bg-[conic-gradient(from_90deg_at_50%_50%,#000_0%,#fff_5%)]" />
      <Button
        {...props}
        className={cn(
          'h-10 px-8 w-full rounded-full text-xl font-semibold text-zinc-800 dark:text-zinc-200 backdrop-blur-xl [-webkit-backdrop-filter:blur(24px)] bg-emerald-50 dark:bg-emerald-900',
          props.className
        )}
      />
    </div>
  );
};
