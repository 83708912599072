import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';
import { defaultLocale, localePrefix, locales, pathnames } from './config';

export const routing = defineRouting({
  locales,
  defaultLocale,
  localePrefix,
  pathnames: pathnames as typeof pathnames & Record<string & {}, string>,
});

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing);
