'use client';

import * as React from 'react';

export function HomeHeader({ text = 'Kshup' }: { text: string }) {
  const [displayedText, setDisplayedText] = React.useState('');

  React.useEffect(() => {
    let currentText = '';
    let index = 0;
    const intervalId = setInterval(() => {
      currentText += text[index];
      setDisplayedText(currentText);
      index++;
      if (index >= text.length) {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [text]);

  return (
    <h2 className="text-xl text-center sm:text-4xl font-bold tracking-tighter md:text-6xl md:leading-[4rem] text-white backdrop-blur-md [-webkit-backdrop-filter:blur(12px)] p-2 rounded-md">
      {displayedText}
    </h2>
  );
}
